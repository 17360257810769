<template>
  <notes-list
    :note-type="NOTE_TYPES.CREDIT"
    @update-total-items="updateTotalItems"
  />
</template>

<script>
import { constants } from '@kingpin-global/kingpin-utils-frontend'
import NotesList from './NotesList.vue'

const { NOTE_TYPES } = constants

export default {
  name: 'CreditNotes',
  components: {
    NotesList,
  },
  data() {
    return {
      NOTE_TYPES,
    }
  },
  emits: ['update-total-count'],
  methods: {
    updateTotalItems(currentPageCount, totalItemsCount) {
      this.$emit('update-total-count', currentPageCount, totalItemsCount)
    },
  },
}
</script>
