import { toTitleCase } from './common-utils'
import { FILTER_FIELD_TYPE } from './constants'

export const mapFilterFieldsAndLabels = (filterFields = [], tableFields = [], excludedFields = []) => {
  if (!(filterFields && filterFields?.length)) {
    return []
  }
  const filterTypes = Object.values(FILTER_FIELD_TYPE)

  let filterFieldsResult = []
  const tableFieldsMap = new Map(
    // tableFields - from list component
    tableFields.map(field => [
      field.key,
      field.label || '',
    ]),
  )
  // filterFields - from backend
  filterFieldsResult = filterFields.map(ff => ({
    ...ff,
    field: ff.name,
    label: tableFieldsMap.get(ff.name) ? tableFieldsMap.get(ff.name) : toTitleCase(ff.name),
  }))

  if (excludedFields.length) {
    filterFieldsResult = filterFieldsResult.filter(item => !(excludedFields.includes(item.name)))
  }
  filterFieldsResult = filterFieldsResult.filter(fil => filterTypes.includes(fil.type))

  return filterFieldsResult
}
