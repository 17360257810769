import { TOGGLE_SIDEBAR } from '@/store/modules/sidebar.module'
import { mapFilterFieldsAndLabels } from '@/table-utils'
import _ from 'lodash'

export const KSearchAndSortMixin = {
  data() {
    return {
      searchText: null,
      sortBy: null,
      editColActive: false,
      filterFields: [],
      filterQuery: {},
    }
  },
  methods: {
    setFilterFields(filterFields = [], tableFields = []) {
      this.filterFields = mapFilterFieldsAndLabels(filterFields, tableFields, [])
    },
    onApplyFilter(filterQuery = {}) {
      this.filterQuery = filterQuery
      this.initPageContent()
    },
    updateSearch: _.debounce(async function updateSearch(value) {
      this.searchText = value
      await this.initPageContent()
    }, 500),
    async updateSort(value) {
      this.sortBy = value
    },
    toggleSidebar() {
      this.editColActive = !this.editColActive
      this.$store.commit(TOGGLE_SIDEBAR)
    },
    updateDemoFilter(value) {
      this.showDemoData = value
      this.initPageContent()
    },
    updateSelfManagedFilter(value) {
      this.showSelfManagedData = value
      this.initPageContent()
    },
    updateWithoutShipmentsFilter(value) {
      this.showOrdersWithoutShipments = value
      this.initPageContent()
    },
  },
}
