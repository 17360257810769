<template>
  <div class="table-padding">
    <div class="filter-sorting-container">
      <k-search-bar-section
        placeholder="Search By Note Id, Status..."
        hide-sort-option
        hide-filter-option
        hide-column-edit-option
        @on-search="updateSearch"
      />
      <multiple-filter
        class="ms-2"
        :filter-fields="filterFields"
        :extra-query-for-string-filter="{noteType}"
        @on-apply-filter="onApplyFilter"
      />
      <k-sorting
        class="ms-auto"
        hide-column-edit-option
        :sort-items="sortFields"
        @update-sort="updateSort"
        @update-sort-direction="updateSortDirection"
      />
    </div>
    <div
      v-if="loading"
      class="spinner-body"
    >
      <b-spinner />
    </div>
    <div
      v-else
      class="table-responsive"
    >
      <p-table
        :fields="tableFields"
        :items="tableItems"
      >
        <template #emptyfiltered>
          <p
            class="
                h2
                d-flex
                table-empty-message
                justify-content-center
                align-items-center
                gap-2
              "
          >
            <b-icon
              icon="exclamation-circle"
              scale="1"
            />
            <span> No Items Found </span>
          </p>
        </template>
        <template #empty>
          <p
            class="
                h2
                d-flex
                table-empty-message
                justify-content-center
                align-items-center
                gap-2
              "
          >
            <b-icon
              icon="exclamation-circle"
              scale="1"
            />
            <span> No Filtered Items </span>
          </p>
        </template>
        <template #cell(noteId)="data">
          <b-link
            class="text-decoration-none text-nowrap"
            :to="{name: ROUTES.EDIT_NOTE.name, params : {id:data.item._id} }"
          >
            {{ data.item.noteId }}
          </b-link>
        </template>
        <template #cell(date)="data">
          {{ formattedDate(data.item.date) }}
        </template>
        <template #cell(amountRemaining)="data">
          {{ formatCurrency(data.item.hasOwnProperty('amountRemaining') ? data.item.amountRemaining : data.item.totalAmountWithVat, data.item.currency) }}
        </template>
        <template #cell(totalAmountWithVat)="data">
          {{ formatCurrency(data.item.totalAmountWithVat, data.item.currency) }}
        </template>
        <template #cell(status)="data">
          <span class="text-capitalize">{{ data.item.status }}</span>
        </template>
        <template #cell(threeDotIcon)="data">
          <div v-if="data.item.status !== CREDIT_DEBIT_NOTE_STATUS.APPLIED">
            <b-dropdown
              size="lg"
              variant="none"
              toggle-class="text-decoration-none p-0"
              class="font-inter custom-dropdown"
              no-caret
            >
              <template #button-content>
                <div>
                  <kingpin-icon
                    icon="VerticalThreeDotIcon"
                    size="24px"
                    class="cursor-pointer kp-text-color"
                  />
                  <b-icon
                    class="me-1 notify-color notify-icon-position"
                    icon="circle-fill"
                    scale="0.3"
                  />
                </div>
              </template>
              <b-dropdown-item :to="{ name: ROUTES.APPLY_NOTE.CHILDREN.BY_NOTE.name, params: {id: data.item._id} }">
                <b-icon
                  class="me-1 notify-color"
                  icon="circle-fill"
                  scale="0.5"
                />
                <span>Apply a Credit Note</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </template>
        <template #cell(userId)="data">
          <b-link
            class="text-decoration-none text-nowrap"
            :to="{name: ROUTES.USERS.USER_DETAILS.name, params : {id:data.item.userId} }"
          >
            {{ data.item.entityName || data.item.userId }}
          </b-link>
        </template>
        <template #cell(shipmentId)="data">
          <b-link
            class="text-decoration-none text-nowrap"
            :to="{name: ROUTES.SHIPMENT_MANAGEMENT.CHILDREN.SHIPMENT_DETAIL_PAGE.name, params : {shipmentId:data.item.shipmentId} }"
          >
            {{ data.item.shipmentSeqId || data.item.shipmentId }}
          </b-link>
        </template>
        <template #cell(appliedTo)="data">
          <span class="text-nowrap">{{ data.item.appliedTo }}</span>
        </template>
      </p-table>
    </div>
    <k-table-pagination
      :total-items="itemsCount"
      @emit-current-page="updateCurrentPage"
      @emit-per-page="updatePerPage"
    />
  </div>
</template>

<script>
import { apiToastErrorV2 } from '@/@core/utils/toast'
import {
  KSearchBarSection,
  KTablePagination,
  KingpinIcon,
  PTable,
} from '@kingpin-global/kingpin-ui'
import {
  BDropdown, BDropdownItem,
  BIcon,
  BLink,
  BSpinner, VBTooltip,
} from 'bootstrap-vue'

import { formattedDate } from '@/@core/utils/format'
import {
  ROUTES,
} from '@/constants'
import { KSearchAndSortMixin } from '@/mixins/k-searbar-section.mixin'
import { FETCH_CREDIT_DEBIT_NOTES } from '@/store/modules/notes.module'
import { getNotesFields } from '@/table-fields-constants'
import { constants, utils } from '@kingpin-global/kingpin-utils-frontend'

const { CREDIT_DEBIT_NOTE_STATUS, NOTE_TYPES } = constants
const { formatCurrency } = utils

export default {
  name: 'NoteList',
  components: {
    PTable,
    KTablePagination,
    BIcon,
    BSpinner,
    BDropdown,
    BDropdownItem,
    BLink,
    KingpinIcon,
    KSearchBarSection,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [KSearchAndSortMixin],
  props: {
    noteType: {
      type: String,
      default: NOTE_TYPES.CREDIT,
    },
  },
  data() {
    return {
      tableItems: [],
      tableFields: [],
      filteredItems: [],
      itemsCount: 0,
      sortBy: '',
      searchText: '',
      currentPage: 1,
      perPage: 10,
      loading: true,
      currentPageCount: 0,
      isDesc: false,
      formattedDate,
      formatCurrency,
      editColActive: false,
      ROUTES,
      CREDIT_DEBIT_NOTE_STATUS,
    }
  },
  computed: {
    noteFields() {
      return getNotesFields()
    },
    sortFields() {
      return this.noteFields.filter(field => !['amountRemaining', 'appliedTo', 'shipmentId'].includes(field.key))
    },
  },
  created() {
    this.initPageContent()
  },
  emits: ['update-total-items'],
  methods: {
    async initPageContent() {
      const queryParams = {
        page: this.currentPage - 1,
        limit: this.perPage,
        search: this.searchText,
        noteType: this.noteType,
      }
      if (this.sortBy) {
        queryParams.sortBy = this.sortBy
        queryParams.asc = !this.isDesc
      }
      if (Object.values(this.filterQuery).length) {
        queryParams.filter = JSON.stringify(this.filterQuery)
      }
      try {
        const res = await this.$store.dispatch(FETCH_CREDIT_DEBIT_NOTES, queryParams)
        this.setTableData(res.data.data.creditAndDebitsList, res.data.data.creditAndDebitsCount)
        this.getTableFields(this.noteFields)
        this.setFilterFields(res.filters, this.noteFields)
      } catch (err) {
        apiToastErrorV2(err)
      } finally {
        this.loading = false
      }
    },
    async setTableData(items, count) {
      this.tableItems = items
      this.itemsCount = count
      this.getCurrentPageCount()
    },
    getTableFields(fields) {
      this.tableFields = fields.filter(field => field.value)
    },
    updateSort(value) {
      this.sortBy = value
    },
    async updateSearch(value) {
      this.searchText = value
      await this.initPageContent()
    },
    async updateCurrentPage(value) {
      this.currentPage = value
      await this.initPageContent()
    },
    async updatePerPage(value) {
      this.perPage = value
      await this.initPageContent()
    },
    async updateSortDirection(value) {
      this.isDesc = value
      await this.initPageContent()
    },
    getCurrentPageCount() {
      const totalCount = this.itemsCount
      const pageCount = totalCount / this.perPage
      if (this.currentPage <= pageCount) {
        this.currentPageCount = this.perPage
      } else {
        this.currentPageCount = Math.ceil(totalCount % this.perPage)
      }
      this.$emit('update-total-items', this.currentPageCount, this.itemsCount)
    },
  },
}
</script>
