export const getOrderTableFields = () => [
  {
    key: 'orderSeqId',
    label: 'Order ID',
    value: true,
    anchor: true,
    index: 0,
    variant: 'anchor-link',
  },
  {
    key: 'createdAt',
    label: 'Order Date',
    value: true,
    index: 1,
  },
  {
    key: 'expectedAvailabilityDate',
    label: 'Estimated Delivery Window',
    value: true,
    index: 2,
  },
  {
    key: 'brandName',
    label: 'Brand Name',
    value: true,
    anchor: true,
    index: 3,
  },
  {
    key: 'retailerName',
    label: 'Retailer Name',
    value: true,
    anchor: true,
    index: 4,
  },
  {
    key: 'paymentTerms',
    label: 'Retailer Payment Terms',
    value: 'true',
    index: 5,
  },
  {
    key: 'collectionName',
    label: 'Collection Name',
    value: true,
    index: 6,
  },
  {
    key: 'status',
    label: 'Order Status',
    value: true,
    index: 7,
  },
  {
    key: 'orderStatusDate',
    label: 'Status Date',
    value: true,
    index: 8,
  },
  {
    key: 'totalPrice',
    label: 'Total Price',
    value: true,
    index: 9,
  },
  {
    key: 'totalQuantity',
    label: 'Total Units',
    value: true,
    anchor: true,
    index: 10,
  },
]

export const getAdminUsersFields = () => [
  { key: '_id', label: 'Id', value: false },
  {
    key: 'name', label: 'Full Name', variant: 'anchor-link', value: true,
  },
  { key: 'email', label: 'Email', value: true },
  { key: 'role', label: 'Admin Type', value: true },
  { key: 'status', label: 'Status', value: false },
]

export const getTeamMateFields = () => [
  {
    key: 'magicLink',
    label: 'Magic Link',
  },
  {
    key: 'firstName',
    label: 'First Name',
    sortable: true,
  },
  {
    key: 'lastName',
    label: 'Last Name',
  },
  {
    key: 'email',
    label: 'Email',
  },
  {
    key: 'role',
    label: 'Role',
  },
]

export const getBrandUsersFields = () => [
  {
    key: '_id',
    label: 'Id',
  },
  {
    key: 'email',
    label: 'Email',
  },
  {
    key: 'status',
    label: 'Status',
  },
  {
    key: 'entityName',
    label: 'Brand Name',
  },
  {
    key: 'companyName',
    label: 'Company Name',
  },
  {
    key: 'profileLink',
    label: 'Magic Link',
  },
  {
    key: 'country',
    label: 'Country',
  },
  {
    key: 'commercialTerms',
    label: 'Commercial Terms',
  },
  {
    key: 'paymentTerms',
    label: 'Payment Terms',
  },
  {
    key: 'isLive',
    label: 'Is Live',
  },
  {
    key: 'collectionName',
    label: 'Last collection',
  },
  {
    key: 'updatedAt',
    label: 'Updated At',
  },
]

export const getUsersFields = () => [
  {
    key: '_id',
    label: 'Id',
  },
  {
    key: 'email',
    label: 'Email',
  },
  {
    key: 'firstName',
    label: 'First Name',
  },
  {
    key: 'lastName',
    label: 'Last Name',
  },
  {
    key: 'profileLink',
    label: 'Magic Link',
  },
  {
    key: 'updatedAt',
    label: 'Updated At',
  },
]

export const getRetailerUsersFields = () => [
  {
    key: '_id',
    label: 'Id',
  },
  {
    key: 'email',
    label: 'Email',
  },
  {
    key: 'status',
    label: 'Status',
  },
  {
    key: 'entityName',
    label: 'Retailer Name',
  },
  {
    key: 'companyName',
    label: 'Company Name',
  },
  {
    key: 'profileLink',
    label: 'Magic Link',
  },
  {
    key: 'country',
    label: 'Country',
  },
  {
    key: 'commercialTerms',
    label: 'Commercial Terms',
  },
  {
    key: 'paymentTerms',
    label: 'Payment Terms',
  },
  {
    key: 'isLive',
    label: 'Is Live',
  },
  {
    key: 'updatedAt',
    label: 'Updated At',
  },
]

export const getShipmentFields = () => [
  {
    key: 'shipmentSeqId',
    label: 'Shipment ID',
    variant: 'anchor-link',
  },
  {
    key: 'orderSeqId',
    label: 'Order ID',
    variant: 'anchor-link',
  },
  {
    key: 'createdAt',
    label: 'Created Date',
  },
  {
    key: 'deliveredDate',
    label: 'Delivered Date',
  },
  {
    key: 'brandName',
    label: 'Brand Name',
  },
  {
    key: 'retailerName',
    label: 'Retailer Name',
  },
  {
    key: 'paymentTerms',
    label: 'Retailer Payment Terms',
  },
  {
    key: 'collectionName',
    label: 'Collection Name',
  },
  {
    key: 'status',
    label: 'Status',
  },
  {
    key: 'totalPrice',
    label: 'Total Price',
  },
  {
    key: 'totalUnits',
    label: 'Total Units',
  },
  {
    key: 'logisticsTerms',
    label: 'Logistics Terms',
  },
]

export const getCollectionFields = () => [
  {
    key: 'name',
    label: 'Name',
  },
  {
    key: 'entityName',
    label: 'Brand',
  },
  {
    key: 'departments',
    label: 'Department',
  },
  {
    key: 'status',
    label: 'Status',
  },
  {
    key: 'productsCount',
    label: 'Number of Products',
  },
  {
    key: 'startDate',
    label: 'Publish Date',
  },
  {
    key: 'endDate',
    label: 'Order deadline',
  },
  {
    key: 'type',
    label: 'Type',
  },
  {
    key: 'deliveryStartDate',
    label: 'Delivery start date',
  },
  {
    key: 'deliveryEndDate',
    label: 'Delivery end date',
  },
  {
    key: 'fromDate',
    label: 'Expected Delivery Start Date',
  },
  {
    key: 'toDate',
    label: 'Expected Delivery End Date',
  },
  {
    key: 'isPremium',
    label: 'Premium',
  },
  {
    key: 'minimumOrderValue',
    label: 'Min Order value (USD)',
  },
  {
    key: 'productsFile',
    label: 'Products',
  },
]

export const getTransactionFields = () => [
  {
    key: 'threeDotIcon',
    label: '',
    value: true,
  },
  {
    key: 'invoiceNumber',
    label: 'Invoice Number',
    value: true,
    index: 1,
    variant: 'anchor-link',
  },
  {
    key: 'orderSeqId',
    label: 'Order Id',
    variant: 'anchor-link',
    value: true,
    index: 2,
  },
  {
    key: 'dueDate',
    label: 'Due Date',
    value: true,
    index: 3,
  },
  {
    key: 'amount',
    label: 'Invoice Amount',
    value: true,
    index: 4,
  },
  {
    key: 'amountWithVat',
    label: 'Amount With Vat',
    value: true,
    index: 5,
  },
  {
    key: 'amountPaid',
    label: 'Amount paid',
    value: true,
    index: 6,
  },
  {
    key: 'amountDue',
    label: 'Amount Due',
    value: true,
    index: 7,
  },
  {
    key: 'totalQuantity',
    label: 'Total Units',
    value: true,
    index: 8,
  },
  {
    key: 'status',
    label: 'Status',
    value: true,
    index: 9,
  },
  {
    key: 'dueIn',
    label: 'Due',
    value: true,
    index: 10,
  },
  {
    key: 'paymentMethod',
    label: 'Payment method',
    value: true,
    index: 11,
  },
  {
    key: 'userId',
    label: 'User Id',
    value: false,
    index: 12,
  },
  {
    key: 'transactionDate',
    label: 'Transaction Date',
    value: false,
    index: 13,
  },
]

export const getNotesFields = () => [
  {
    key: 'threeDotIcon',
    label: '',
    value: true,
  },
  {
    key: 'noteId',
    label: 'Note Id',
    value: true,
    variant: 'anchor-link',
  },
  {
    key: 'date',
    label: 'Note Issued Date',
    value: true,
  },
  {
    key: 'userId', // Temporarily displaying user ID here it should come the user name
    label: 'Account',
    value: true,
    variant: 'anchor-link',
  },
  {
    key: 'shipmentId',
    label: 'Reference Shipment ID',
    value: true,
    variant: 'anchor-link',
  },
  {
    key: 'totalAmountWithVat',
    label: 'Amount After Vat',
    value: true,
  },
  {
    key: 'amountRemaining', // For temporarily its displaying totalAmountwithVat
    label: 'Amount Remaining',
    value: true,
  },
  {
    key: 'status',
    label: 'Status',
    value: true,
  },
  {
    key: 'appliedTo', // Leaving empty for now
    label: 'Applied To',
    value: true,
  },
]

export const getScheduledCollectionFields = () => [
  {
    key: 'entityName',
    label: 'Brand Name',
  },
  {
    key: 'seasonType',
    label: 'Season',
  },
  {
    key: 'month',
    label: 'Scheduled Launch Month',
  },
  {
    key: 'updatedAt',
    label: 'Updated Date',
  },
  {
    key: 'emailsSent',
    label: 'Emails sent',
  },
]

export const getCreditApplicationFields = () => [
  {
    key: '_id',
    label: 'Id',
    value: true,
  },
  {
    key: 'retailerName',
    label: 'Retailer',
    value: true,
  },
  {
    key: 'contactName',
    label: 'Contact',
    value: true,
  },
  {
    key: 'creditAmount',
    label: 'Amount',
    value: true,
  },
  {
    key: 'paymentTerms',
    label: 'Payment Terms',
    value: true,
  },
  {
    key: 'status',
    label: 'Status',
    value: true,
  },
  {
    key: 'securityDocumentType',
    label: 'Credit Document',
    value: true,
  },
  {
    key: 'createdAt',
    label: 'Date of creation',
    value: true,
  },
]

export const getWalletFields = () => [
  { key: '_id', label: 'Wallet Rule Id', variant: 'anchor-link' },
  { key: 'brand', label: 'Brand', variant: 'anchor-link' },
  { key: 'retailer', label: 'Retailer', variant: 'anchor-link' },
  { key: 'commercialTerms', label: 'Commercial Terms' },
  { key: 'paymentTerms', label: 'Payment Terms' },
  { key: 'value', label: 'Price multiplier' },
  { key: 'discount', label: 'Discount' },
  { key: 'logisticsTerms', label: 'Logistics Terms' },
  { key: 'accountType', label: 'Type of Account' },
  { key: 'appliedTo', label: 'Applied To' },
  { key: 'status', label: 'Status' },
]
