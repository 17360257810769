export function formattedDate(date = null, joinWith = '/') {
  if (!date) return ''
  const d = new Date(date)
  if (!(d instanceof Date && !isNaN(d))) return 'Invalid Date'
  return [d.getDate(), d.getMonth() + 1, d.getFullYear()]
    .map(n => (n < 10 ? `0${n}` : `${n}`))
    .join(joinWith)
}

export const formatPrice = value => {
  if (!value) return ''
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const capitalize = string => string.charAt(0).toUpperCase() + string.slice(1)

export const getValueWithSymbol = (value, symbol = '', isPrefix = false) => {
  if (value || value === 0) {
    if (isPrefix) {
      return `${symbol}${value}`
    }
    return `${value}${symbol}`
  }
  return '--'
}

export const formatDateForInput = date => {
  if (!date) return ''
  date = date.split('/').reverse().join('-')
  date = date.replaceAll('/', '-')
  return date
}

export const getRemainingDays = expiryDate => {
  const oneDay = 24 * 60 * 60 * 1000
  const firstDate = new Date()
  const secondDate = new Date(expiryDate)
  return Math.round((secondDate - firstDate) / oneDay) + 1
}
